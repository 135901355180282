import { render, staticRenderFns } from "./PersonalDetail.vue?vue&type=template&id=6c38dd82&scoped=true&"
import script from "./PersonalDetail.ts?vue&type=script&lang=ts&"
export * from "./PersonalDetail.ts?vue&type=script&lang=ts&"
import style0 from "./PersonalDetail.vue?vue&type=style&index=0&id=6c38dd82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c38dd82",
  null
  
)

export default component.exports