const dateParse = (date: any) => {
  return date != null ? new Date(`${date} 00:00:01`) : date
}
const dateFormat = (date: any) => {
  return new Date(date).toISOString().split('T')[0]
}
const dateFormat_db = (date: any) => {
  return date != null ? date.toISOString().split('T')[0] : date
}
const phoneFormat = (phone: any) => {
  return phone != null ? phone.slice(1, -1) : phone
}
const fileParse = (fileDb: any) => {
  return fileDb != null
    ? new File(['file'], fileDb.substring(fileDb.lastIndexOf('/') + 1))
    : null
}
const documentStatus = () => {
  return [
    { id: 1, name: 'Falta', value: 1 },
    { id: 2, name: 'Vencido', value: 2 },
    { id: 3, name: 'Presentado', value: 3 },
    { id: 4, name: 'En archivo', value: 4 },
    { id: 5, name: 'Pendiente', value: 5 },
  ]
}

const genNextPaymentDate = (accountBalance: number, monthly_quota: number) => {
  const newDay = new Date()
  //const day = newDay.getDate()
  const month = newDay.getMonth() + 1
  let year = newDay.getFullYear()
  let calfecha = ''
  let calmes = Math.round(month + accountBalance / monthly_quota + 1)
  //console.log(calmes)
  while (calmes > 12) {
    calmes = calmes - 12
    year = year + 1
  }
  calfecha = `${year}/${calmes}/${1}`
  //console.log(calfecha)
  const nextPaymentDate = new Date(calfecha)
  //console.log(nextPaymentDate)
  return nextPaymentDate
}
export {
  dateParse,
  dateFormat,
  phoneFormat,
  dateFormat_db,
  documentStatus,
  genNextPaymentDate,
  fileParse,
}
