import { Component, Vue } from 'vue-property-decorator'
import http from '@/services/http'
import * as Helpers from '@/helpers'
import Crm from '@/Models/Crm'
import menuModule from '@/store/menu'
import CCSS from '@/Models/CCSS'
import Animation from '@/components/Animation/index.vue'

@Component({
  components: {
    Animation,
  },
})
export default class ViewPersonalDetail extends Vue {
  baseUrl = ''

  crm_data: Crm = new Crm()
  residency_data: any = {}
  CCSS_data: CCSS = new CCSS()
  loading = false
  observation_data = []
  insurance_data: Array<any> = []

  headerObservation: Array<any> = [
    {
      field: 'date_read',
      label: 'Fecha',
    },
    {
      field: 'observation',
      label: 'Observacion',
    },
  ]
  headerInsurance: Array<any> = [
    {
      field: 'client_member_number',
      label: '# miembro',
    },
    {
      field: 'name_client',
      label: 'Nombre cliente',
    },
    {
      field: 'policy_number',
      label: '# poliza',
    },
    {
      field: 'period_type_read',
      label: 'Periodicidad',
    },
    {
      field: 'policy_state_read',
      label: 'Poliza estado',
    },
    {
      field: 'date_start_read',
      label: 'Proximo pago',
    },
    {
      field: 'premium_amount',
      label: 'Monto prima',
    },
  ]
  async mounted() {
    this.baseUrl = process.env.VUE_APP_API_URL
    await this.getObservationCrm()
  }
  async getObservationCrm() {
    const data_insurance: Array<any> = []
    try {
      const id = menuModule.id_param
      const res = await http.get(`/api/crm/crm_client_file/${id}/`)
      if (!res.data) throw new Error(res.data)
      console.log(res.data, 'data')

      this.crm_data = res.data.crm_data.member[0]
      this.CCSS_data = {
        ...res.data.ccss_data.ccss[0],
        member_number: res.data.ccss_data.ccss[0].member_number.member_number,
        automatic_charge_applies:
          res.data.ccss_data.ccss[0].automatic_charge_applies == 1
            ? 'Si'
            : 'No',
        monthly_quota:
          res.data.ccss_data.ccss[0].monthly_quota != null
            ? res.data.ccss_data.ccss[0].monthly_quota
            : '',
        next_payment_date:
          res.data.ccss_data.ccss[0].account_balance &&
          res.data.ccss_data.ccss[0].monthly_quota != null
            ? Helpers.genNextPaymentDate(
                res.data.ccss_data.ccss[0].account_balance,
                res.data.ccss_data.ccss[0].monthly_quota,
              )
                .toISOString()
                .split('T')[0]
            : new Date().toISOString().split('T')[0],
        status: res.data.ccss_data.ccss[0].status.status,
        type_tariff: res.data.ccss_data.ccss[0].type_tariff.tariff_type,
      }

      if (res.data.residency_data.residency[0]) {
        this.residency_data = {
          ...res.data.residency_data.residency[0],
          member_number:
            res.data.residency_data.residency[0].member_number.member_number,
          residency_type:
            res.data.residency_data.residency[0].residency_type.residency_type,
        }
      }
      if (res.data.insurance_data) {
        res.data.insurance_data.forEach((element: any) => {
          data_insurance.push(element.insurance)
        })

        this.insurance_data = data_insurance.map((obj: any) => ({
          ...obj,
          name_client: obj.member_number.client_name,
          client_member_number: obj.member_number.member_number,
          period_type: obj.period_type.id,
          policy_type: obj.policy_type.id,
          policy_state: obj.policy_state.id,
          policy_state_read: obj.policy_state.policy_state,
          period_type_read: obj.period_type.period_type,
          date_start_read: obj.date_start,
          date_start: Helpers.dateParse(obj.date_start),
          date_next_payment: Helpers.dateParse(obj.date_next_payment),
        }))
      }
      if (
        res.data.residency_data.observations !=
        'Este miembro no tiene observaciones en la residencia'
      ) {
        this.observation_data = res.data.residency_data.observations.map(
          (obj: any) => ({
            id: obj.id,
            date: Helpers.dateParse(obj.date),
            date_read: Helpers.dateFormat(obj.date),
            observation: obj.observation,
          }),
        )
      }
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
}
